@import '../../styles/variables.scss';

.main {
  & .showcase {
    width: 100%;
    padding: 10vw 11vw;
    padding-bottom: 15vw;
    box-sizing: border-box;

    & > h2 {
      margin: 0;
      margin-bottom: 30px;
      margin-left: -4px;
    }

    & .case-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @media #{$iphone5} {
      padding: 80px 20px!important;
    }

    @media #{$small} {
      padding: 80px 40px;

      & > h2 {
        margin-bottom: 10px;
        margin-left: 0;
      }

      & .case:last-child {
        margin-bottom: 0;
      }
    }

    @media #{$ipad} {
      padding: 18vw 11vw;
      
      & .case:last-child {
        margin-bottom: 20px;
      }
    }

    @media #{$ipad-landscape} {
      padding: 8vw 44px;
    }

    @media #{$ipad-pro-portrait} {
      & > h2 {
        margin-bottom: 40px;
        margin-left: 0;
      }

      & .case:last-child {
        margin-bottom: 0;
      }
    }
  }
}
