@import './variables.scss';

@font-face {
  font-family: 'font-bold';
  src: url('./fonts/font-bold.woff'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'font-regular';
  src: url('./fonts/font-regular.woff'); /* IE9 Compat Modes */
}

html {}

body {
  margin: 0;
  height: 100%;
  max-width: 100vw;
  font-family: 'font-bold';
}

a {
  color: #FFF;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    outline: 0;
    border: none;
    -moz-outline-style: none;
    -webkit-tap-highlight-color: transparent;
  }
}

span {
  color: #8c8c91;
  font-weight: bold;
  display: block;
  font-size: calc(12px + 0.05vw);
}

p {
  margin: 0;
  letter-spacing: 1px;
  /*  font-size: calc(26px - 0.5vw); */
  /* line-height: 34px; */
  font-size: calc(8px + 0.6vw);
  line-height: calc(19px + 0.4vw);
  font-family: 'font-regular';

  @media #{$iphone5} {
    font-size: 14px!important;
    line-height: 24px;
  }

  @media #{$small} {
    font-size: 15px;
    line-height: 24px;
  }

  @media #{$ipad} {
    font-size: 24px;
    line-height: 32px;
  }

  @media #{$ipad-pro-portrait} {
    font-size: 38px;
    line-height: 50px;
  }

  @media #{$ipad-landscape} {
    font-size: 18px;
    line-height: 26px;
  }
}

h1 {
  /* font-size: 62px;
  line-height: 70px; */
  margin: 0;
  font-size: calc(30px + 2.2vw);
  line-height: calc(40px + 2.2vw);
  font-weight: initial;

  @media #{$small} {
    font-size: 28px;
    line-height: 36px;
  }

  @media #{$ipad-pro-portrait} {
    font-size: 68px;
    line-height: 76px;
  }

  @media #{$ipad-landscape} {
    font-size: 42px;
    line-height: 48px;
  }
}

h2 {
  /*  font-size: 52px;
  line-height: 50px; */
  font-size: calc(18px + 2vw);
  line-height: calc(22px + 2vw);
  font-weight: initial;

  @media #{$small} {
    font-size: 34px;
    line-height: 38px;
  }

  @media #{$ipad} {
    font-size: 52px;
    line-height: 60px;
  }

  @media #{$ipad-landscape} {
    font-size: 42px;
    line-height: 48px;
  }

  @media #{$ipad-pro-portrait} {
    font-size: 68px;
    line-height: 76px;
  }
}

h3 {
  font-size: 32px;

  @media #{$small} {
    font-size: 24px;
  }

  @media #{$ipad-pro-portrait} {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 0;
  }
}

h6 {
  @media #{$small} {
    font-size: 15px;
  }
}

.App {
  height: 100%;
  width: 100%;
}

